html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: 'Poppins';
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

/* Trying to override specific agent styles */
input:-internal-autofill-selected,
input:-webkit-autofill {
  background-color: transparent !important;
  color: #63727b !important;
}

/* 
 It seems that google tag manager is injecting an image that causes a white space 
 a the bottom of every screen
*/
body > img {
  display: none;
}
