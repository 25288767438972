/**
 * See available variables
 * https://pubnub.github.io/react-chat-components/docs/?path=/docs/ui-customization-css-variables--page
 * I hate the !important but it's the only way I found to override these variables
 * because PubNub styles are put below our stylesheets ... so.
 * I tried to use css-loader style-loader to customize that but got tons of errors
 * and after debugging a lot I gave up.
 * Here's what I was exploring if you feel curious: 
 * https://webpack.js.org/loaders/style-loader/#insert
*/
:root {
  --msg-list__background: white !important;
  --msg-input__background: transparent !important;
  --msg-input__textarea__background: white !important;
  --msg--hover__background: white !important;
  --coop-primary: #415077 !important;
  --coop-secondary: #f85731 !important;
  --msg--hover__background: transparent !important;
  --msg__padding: 20px 16px !important;
}

/**
  Quick fix to avoid scrollbars making the UI flicker while main content is loaded
  See: https://doublenines.atlassian.net/browse/COOPFE-9316
*/
@media screen and (min-width: 992px) {
  html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
  }
}

body.with-dialog-open {
  overflow: hidden;
}

.force-full-height {
  height: 100%;
  min-height: 100% !important;
}

.no-scroll {
  overflow: hidden !important;
}
